import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

export interface IAlert {
  open: boolean
  type: 'success' | 'warning' | 'error' | 'info'
  message: string
  detailedMessages?: string[]
}

interface IAlertSnackbarProps extends IAlert {
  onClose: () => void
}

const AlertSnackbar = (props: IAlertSnackbarProps) => {
  return (
    <Snackbar
      open={props.open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={10000}
      onClose={props.onClose}
      sx={{ bottom: { xs: 16 }, width: { sm: '100%' } }}
    >
      <Alert
        variant='filled'
        severity={props.type}
        onClose={props.onClose}
        sx={{
          marginX: 1,
          width: '100%',
          '.MuiAlert-message': {
            width: '100%',
          },
        }}
      >
        {props.message}
        {props?.detailedMessages && props?.detailedMessages?.length > 0 && (
          <List sx={{ maxHeight: 400, overflow: 'auto' }}>
            {props?.detailedMessages?.map((msg, idx) => (
              <ListItem key={`message-${idx}`}>
                <Typography>{msg}</Typography>
              </ListItem>
            ))}
          </List>
        )}
      </Alert>
    </Snackbar>
  )
}

export default AlertSnackbar
