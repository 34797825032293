import { parse, set } from 'date-fns'

export const SHORT_DATE = 'dd MMM yyyy'
export const LONG_DATE = 'dd MMMM yyyy'
export const LONG_DATE_TIME = 'dd MMMM yyyy HH:mm'
export const LONG_DATE_TIME_SECONDS = 'dd MMMM yyyy HH:mm:ss'
export const SHORT_DATE_SLASH = 'dd/MM/yy'
export const LONG_DATE_SLASH = 'dd/MM/yyyy'
export const TZ_DATE = 'yyyy-MM-dd'
export const TZ_DATE_TIME = 'yyyy-MM-dd HH:mm:ss xxxx'
export const TIME_HOURS_MINUTES = 'HH:mm'
export const YEAR = 'yyyy'

// --- Common date helper functions
export const parseDate = (date: string) => parse(date, TZ_DATE, new Date())
export const parseShortDate = (date: string) => parse(date, SHORT_DATE, new Date())
export const parseLongDate = (date: string) => parse(date, LONG_DATE, new Date())
export const parseLongDateTime = (date: string) => parse(date, LONG_DATE_TIME, new Date())
export const parseShortDateSlash = (date: string) => parse(date, SHORT_DATE_SLASH, new Date())
export const parseLongDateSlash = (date: string) => parse(date, LONG_DATE_SLASH, new Date())
export const parseTZDate = (date: string) => parse(date, TZ_DATE, new Date())
export const parseTZDateTime = (date: string) => parse(date, TZ_DATE_TIME, new Date())
export const resetTime = (date: Date) => set(date, { hours: 0, minutes: 0, seconds: 0 })
export const parseMonth = (month: number) => parse(month.toString(), 'M', new Date())
export const parseYear = (date: string) => parse(date, YEAR, new Date())
export const parseYearMonth = (year: string, month: number) =>
  parse(`${year}-${month.toString()}`, 'yyyy-M', new Date())
export const parseTimeHHmm = (time: string) => parse(time, TIME_HOURS_MINUTES, new Date())
