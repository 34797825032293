import { ReactElement, ReactNode, useState, useEffect } from 'react'
import eventEmitter, { EVENT_ALERT } from 'common/utils/event-emitter'
import Head from 'next/head'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import ApiProviderContext from 'common/context/api-provider-context'
import CssBaseline from '@mui/material/CssBaseline'
import AlertSnackbar, { IAlert } from 'common/components/ui/alert-snackbar'
import { ServiceContextProvider } from 'common/context/service-context'
import { theme } from 'common/theme'
import { ThemeProvider } from '@mui/material/styles'

type NextAuthSession = { session: Session }

export type NextPageWithLayout = NextPage<NextAuthSession> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps<NextAuthSession> & {
  Component: NextPageWithLayout
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const [alert, setAlert] = useState<IAlert>({} as IAlert)

  useEffect(() => {
    eventEmitter.addListener(EVENT_ALERT, (alt: IAlert) => setAlert({ ...alt, open: true }))
    return () => eventEmitter.removeAllListeners(EVENT_ALERT)
  }, [])

  return (
    <>
      <Head>
        <title>QGrants</title>
        <meta name='description' content='QGrants' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link rel='icon' href='/favicon.ico' />
      </Head>

      <ThemeProvider theme={theme}>
        <CssBaseline />

        <AlertSnackbar
          open={alert.open}
          type={alert.type}
          message={alert.message}
          detailedMessages={alert?.detailedMessages}
          onClose={() => setAlert({ ...alert, open: false, message: '' })}
        />

        {/* NOTE: QGrants accessToken expires every 30 mins. Set refetchInterval to about 25 mins. */}
        <SessionProvider session={pageProps.session} refetchInterval={1500}>
          <ApiProviderContext>
            <ServiceContextProvider>
              {Component.getLayout ? Component.getLayout(<Component {...pageProps} />) : <Component {...pageProps} />}
            </ServiceContextProvider>
          </ApiProviderContext>
        </SessionProvider>
      </ThemeProvider>
    </>
  )
}

export default App
