import { useMemo } from 'react'
import { useSession } from 'next-auth/react'
import { Configuration } from 'generated/restapi-sc'

export const useRestConfig = () => {
  const { data: session, status: sessionStatus } = useSession()

  const accessToken = useMemo(() => {
    return session && sessionStatus === 'authenticated' ? session.accessToken : ''
  }, [session, sessionStatus])

  const config = useMemo(
    () =>
      new Configuration({
        basePath: process.env.NEXT_PUBLIC_BASE_URL,
        accessToken: accessToken,
      }),
    [accessToken]
  )

  return { config, isConfigReady: Boolean(accessToken) }
}
